<template>
  <div class="uk-container">
    <vue-headful
      description="handle.fi ecosystem statistics."
      image="src/assets/logo.png"
      :title="`${isLite ? 'handleLite' : 'handle.fi'} | data`"
    />

    <div id="data">
      <h2 class="uk-margin-remove">data</h2>
      <h5 class="uk-margin-remove-top uk-text-light">
        handle.fi ecosystem statistics
      </h5>

      <Loading v-if="loading" scale=".5" />

      <table
        class="uk-table uk-responsive uk-table-xs uk-table-divider uk-margin-remove-top"
        v-if="!loading && sdk"
      >
        <thead class="hfi-data-table-header hfi-border-top-remove">
          <tr>
            <th class="">fxToken</th>
            <th class="uk-text-right">total supply</th>
            <th class="uk-text-right">price (USD)</th>
            <th class="uk-text-right">value (USD)</th>
          </tr>
        </thead>

        <tbody>
          <tr
            :key="fxToken.name"
            v-for="fxToken in sdk.protocol.fxTokens.filter(
              (tok) => tok.totalSupply > 0
            )"
          >
            <td class="uk-flex uk-flex-middle uk-text-nowrap">
              <img
                class="uk-margin-small-right"
                width="22"
                :src="fxToken.symbol + 'Logo.png'"
              />
              {{ fxToken.symbol }}
            </td>
            <td class="uk-text-right">
              {{ parsedFxTokenData[fxToken.symbol].totalSupply }}
            </td>
            <td class="uk-text-right">
              {{ parsedFxTokenData[fxToken.symbol].usdPrice }}
            </td>
            <td class="uk-text-right">
              {{ parsedFxTokenData[fxToken.symbol].usdValue }}
            </td>
          </tr>

          <tr>
            <td class="uk-flex uk-flex-middle uk-text-nowrap">
              <img
                class="uk-margin-small-right"
                width="22"
                src="handle.fiLogoCutInverted.svg"
              />
              total
            </td>

            <td></td>
            <td></td>

            <td class="uk-text-right">
              {{
                parseFloat(
                  ethers.utils.formatEther(fxTokenTotalValue)
                ).toLocaleString(undefined, digits(2))
              }}
            </td>
          </tr>
        </tbody>
      </table>

      <table
        class="uk-table uk-responsive uk-table-xs uk-table-divider uk-margin-remove-top"
        v-if="!loading && showMetricsTable"
      >
        <thead class="hfi-data-table-header hfi-border-top-remove">
          <tr>
            <th class="">metric</th>
            <th class="uk-text-right">value</th>
          </tr>
          <tr :key="row.metric" v-for="row in metricRows">
            <td class="uk-text-left">
              {{ row.metric }}
            </td>
            <td class="uk-text-right">
              {{ row.value }}
            </td>
          </tr>
        </thead>

        <tbody>
          <tr v-for="statistic in statistics">
            <td class="uk-flex uk-flex-middle uk-text-nowrap">
              total ETH staked
            </td>

            <td class="uk-text-right">
              {{
                parseFloat(statistic.ethStaked).toLocaleString(
                  undefined,
                  digits(2)
                )
              }}
            </td>
          </tr>

          <tr v-for="statistic in statistics">
            <td class="uk-flex uk-flex-middle uk-text-nowrap">
              network collateralisation ratio (%)
            </td>

            <td class="uk-text-right">
              {{
                (parseFloat(statistic.collatRatio) * 100).toLocaleString(
                  undefined,
                  digits(2)
                )
              }}
            </td>
          </tr>

          <tr v-for="statistic in statistics">
            <td class="uk-flex uk-flex-middle uk-text-nowrap">
              total fxToken trading volume (USD)
            </td>

            <td class="uk-text-right">
              {{
                parseFloat(statistic.tradingVolume).toLocaleString(
                  undefined,
                  digits(2)
                )
              }}
            </td>
          </tr>

          <tr v-for="statistic in statistics">
            <td class="uk-flex uk-flex-middle uk-text-nowrap">
              total trading fees (USD)
            </td>

            <td class="uk-text-right">
              {{
                parseFloat(statistic.tradingFees).toLocaleString(
                  undefined,
                  digits(2)
                )
              }}
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="!loading && !showMetricsTable">
        <a
          class="hfi-link"
          href="https://defillama.com/protocol/handle-fi"
          target="_blank"
        >
          click here for more TVL data - powered by defillama
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { closeAllNotifications, showNotification } from "@/utils/utils";
import { store } from "@/store";
import { ethers } from "ethers";
import { Readers } from "handle-sdk";
import Loading from "@/components/Loading";

export default {
  name: "Data",
  components: {
    Loading,
  },
  data() {
    return {
      showMetricsTable: false,
      statistics: [],
      fxTokenTotalValue: ethers.constants.Zero,
      /**
       * @type {{
       *   totalSupply: string,
       *   usdPrice: string,
       *   usdValue: string,
       * }}
       */
      parsedFxTokenData: {},
      /**
       * @type {{
       *   metric: string,
       *   value: string
       * }[]}
       */
      metricRows: [],
      ethers,
      eth_usd: ethers.constants.Zero,
      walletUpdateEventId: null,
    };
  },
  computed: {
    isLite() {
      return store.state.isLite;
    },
    network() {
      return store.state.network;
    },
    sdk() {
      return store.state.refHandleSDK.get();
    },
    loading() {
      return !Object.keys(this.parsedFxTokenData).length;
    },
  },
  watch: {
    async sdk() {
      if (!this.sdk) return;
      await this.getData();
    },
  },
  async mounted() {
    showNotification("gorilla", "loading data, please wait", undefined, 0);
    await this.getData();
  },
  methods: {
    async getData() {
      if (!this.sdk) return;

      this.fxTokenTotalValue = ethers.constants.Zero;
      this.metricRows = [];
      this.statistics = [];
      this.parsedFxTokenData = {};
      this.eth_usd = ethers.constants.WeiPerEther.pow(2).div(
        this.sdk.protocol.getFxTokenBySymbol("fxUSD").rate
      );

      // Parse fxToken data for rendering.
      for (let fxToken of this.sdk.protocol.fxTokens) {
        const usdPrice = fxToken.rate
          .mul(this.eth_usd)
          .div(ethers.constants.WeiPerEther);
        const usdValue = fxToken.rate
          .mul(this.eth_usd)
          .mul(fxToken.totalSupply)
          .div(ethers.constants.WeiPerEther.pow(2));
        this.parsedFxTokenData[fxToken.symbol] = {
          totalSupply: parseFloat(
            ethers.utils.formatEther(fxToken.totalSupply)
          ).toLocaleString(undefined, this.digits(2)),
          usdPrice: parseFloat(
            ethers.utils.formatEther(usdPrice)
          ).toLocaleString(undefined, this.digits(2)),
          usdValue: parseFloat(
            ethers.utils.formatEther(usdValue)
          ).toLocaleString(undefined, this.digits(2)),
        };
        this.fxTokenTotalValue = this.fxTokenTotalValue.add(usdValue);
      }

      if (!this.showMetricsTable) {
        closeAllNotifications();
        return;
      }

      const getFxTokenSymbolByAddress = (address) =>
        this.sdk.protocol.fxTokens.find((x) => x.address === address).symbol;

      // Push vault count to table
      const vaultRegistry = await Readers.readVaultRegistry(this.sdk.gqlClient);
      for (let registry of vaultRegistry) {
        const fxTokenSymbol = getFxTokenSymbolByAddress(registry.fxToken);
        this.metricRows.push({
          metric: `${fxTokenSymbol} vaults`,
          value: registry.owners.length,
        });
      }

      // Push collateral balances to table
      for (let collateralToken of this.sdk.protocol.collateralTokens) {
        this.metricRows.push({
          metric: `Treasury ${collateralToken.symbol}`,
          value: `${parseFloat(
            ethers.utils.formatEther(collateralToken.totalBalance)
          ).toLocaleString(undefined, this.digits(2))} ${
            collateralToken.symbol
          }`,
        });
      }

      // Fetch lowest CR per fxToken and push to table
      for (let fxToken of this.sdk.protocol.fxTokens) {
        const gqlFilter = {
          first: 1,
          where: { fxToken: fxToken.address.toLowerCase() },
          orderBy: "collateralRatio",
          orderDirection: "asc",
        };
        const vault = await Readers.queryVault(this.sdk.gqlClient, gqlFilter);
        if (vault == null) continue;
        this.metricRows.push({
          metric: `lowest ${fxToken.symbol} CR`,
          value: `${(
            parseFloat(ethers.utils.formatEther(vault.collateralRatio)) * 100
          ).toLocaleString(undefined, this.digits(2))}%`,
        });
      }

      // Fetch keeper pool data.
      const keeperPools = await Readers.queryFxKeeperPools(
        this.sdk.gqlClient,
        {}
      );
      for (let pool of keeperPools) {
        const fxTokenSymbol = getFxTokenSymbolByAddress(pool.fxToken);
        this.metricRows.push({
          metric: `${fxTokenSymbol} keeper TVL`,
          value: `${parseFloat(
            ethers.utils.formatEther(pool.totalDeposits)
          ).toLocaleString(undefined, this.digits(2))} ${fxTokenSymbol}`,
        });
        this.metricRows.push({
          metric: `${fxTokenSymbol} keeper liquidations`,
          value: pool.liquidationsExecuted.toString(),
        });
      }

      closeAllNotifications();
    },

    digits(numDigits) {
      return {
        minimumFractionDigits: numDigits,
        maximumFractionDigits: numDigits,
      };
    },
  },
};
</script>

<style lang="scss">
@use "src/assets/styles/handle.fi" as handle;

.hfi-data-table-header {
  padding: 0.5rem 1rem;
  border: 2px solid handle.$green;
}

.hfi-data-header-row {
  padding: 0.5rem 1rem;
  border: 2px solid handle.$green !important;
}
</style>
